import tublianFTFHackChallenge from "../../../images/challenges/techfest-tublian.jpg";

export const activeChallenges: Challenge[] = [
  {
    id: "606",
    chalName: "Build a 8020 Transcriber",
    description: "Build an API that transcribes youtube video to text",
    endDate: "Jan 25th - 12:00 pm EST",
    chalStatus: "Open",
    imageUrl: "https://tublian-newsletter-assets.s3.us-east-1.amazonaws.com/challenge-606.jpg",
  },

];

export const previousChallenges: Challenge[] = [
  {
    id: "205",
    chalName: "8020 Challenge: Learn & Earn!",
    description: "Complete a Roadmap & Earn $100",
    endDate: "20th Dec - 12:00 pm EST",
    chalStatus: "Closed",
    imageUrl: "https://www.tublian.com/static/media/startLearning.9188bdc7.png",
  },
  {
    id: "605",
    chalName: "Build Certificate Generator",
    description: "Build an End to End Certificate Generator",
    endDate: "31st Oct - 12:00 pm EST",
    chalStatus: "Closed",
    imageUrl: "https://tublian-newsletter-assets.s3.amazonaws.com/challange-605.jpg",
  },
  {
    id: "604",
    chalName: "Tublian AI Hack Challenge",
    description: "Ignite Change Through Collaboration",
    endDate: "28th Aug - 12:00 pm EST",
    chalStatus: "Closed",
    imageUrl: tublianFTFHackChallenge,
  },
  {
    id: "603",
    chalName: "DevDocGenie",
    description: "Always-Updated API Assistance for Developers",
    endDate: "31st Aug - 6:00 pm EST",
    chalStatus: "Closed",
    imageUrl:
      "https://tublian-newsletter-assets.s3.amazonaws.com/docgenie-challenge.jpg",
  },
  {
    id: "602",
    chalName: "Build an AI SQL Agent",
    description: "Build an Q&A agent over sql database",
    endDate: "21st June - 6:00 pm EST",
    chalStatus: "Closed",
    imageUrl:
      "https://tublian-newsletter-assets.s3.amazonaws.com/ai-internship2.jpg",
  },
  {
    id: "601",
    chalName: "Build an AI ChatBot",
    description: "Build your own personal chatbot to help you teach AI.",
    endDate: "31st May - 6:00 pm EST",
    chalStatus: "Closed",
    imageUrl:
      "https://tublian-newsletter-assets.s3.amazonaws.com/ai-internship-challenge-1.jpg",
  },
  {
    id: "204",
    chalName: "Frontend Challenge",
    description:
      "Implement a dashboard for a developer community using Figma design.",
    endDate: "31th May - 6:00 pm EST",
    chalStatus: "Closed",
    imageUrl:
      "https://tublian-newsletter-assets.s3.amazonaws.com/frontend-challenge-204.jpg",
  },
  {
    id: "203",
    chalName: "Frontend Challenge",
    description:
      "Implement the front end of this application, transform the design into a pixel-perfect functional interface.",
    endDate: "5th Apr - 6:00 pm EST",
    chalStatus: "Started",
    imageUrl:
      "https://tublian-newsletter-assets.s3.amazonaws.com/frontend-challenge-203.jpg",
  },
  {
    id: "304",
    chalName: "Technical Writing Challenge",
    description: "Write a Blog about your Open Source experience with Tublian",
    endDate: "16th Feb - 6:00 pm EST",
    chalStatus: "Started",
  },
  {
    id: "303",
    chalName: "Build a RAG application using LLM",
    description:
      "Build a Retrieval Augmented Generation (RAG) application using LLM and Langchain",
    endDate: "19th Jan - 6:00 pm EST",
    chalStatus: "Started",
  },
  {
    id: "202",
    chalName: "Frontend Challenge",
    description:
      "Implement the front end of this application, transform the design into a pixel-perfect functional interface.",
    endDate: "15th Dec - 6:00 pm EST",
    chalStatus: "Started",
  },
  {
    id: "101",
    chalName: "Prompt Engineering Challenge",
    description:
      "Build a Discord bot named “tubliBot”. You're to use Prompt Engineering & ChatGPT to generate the entire solution.",
    endDate: "22nd Sept - 6:00 pm EST",
    chalStatus: "Completed",
  },
];

type Challenge = {
  chalName: string;
  description: string;
  endDate: string;
  chalStatus: string;
  id: string;
  imageUrl?: string;
};
